import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '@environment';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RestaurantsApiService {
  constructor(private http: HttpClient) {}

  getOrderStatuses(accessCode: string) {
    let url = `${environment.apiUrl}/display/couriers`;

    let params = new HttpParams()
      .set('access_code', accessCode);
      //.set('date', '11-10-2021');

    return this.http.get<any>(url, { params });
  }

  getKitchenDisplayOrders(accessCode: string) {
    let url = `${environment.apiUrl}/display/kitchen`;

    let params = new HttpParams()
      //.set('date', '04-11-2021')
      .set('access_code', accessCode);

    return this.http.get<any>(url, { params });
  }

  getEnabledProductList(accessCode: string, identifier: string) {
    let url = `${environment.apiUrl}/display/kitchen/enabled`;

    let params = new HttpParams()
      .set('access_code', accessCode)
      .set('identifier', identifier);

    return this.http.get<any>(url, { params });
  }

  getColorProductList(accessCode: string, identifier: string) {
    let url = `${environment.apiUrl}/display/kitchen/color`;

    let params = new HttpParams()
      .set('access_code', accessCode)
      .set('identifier', identifier);

    return this.http.get<any>(url, { params });
  }

  readyOrder(accessCode: string, orderRrn: string) {
    let url = `${environment.apiUrl}/display/kitchen/ready`;

    return this.http.post<any>(url, {
      access_code: accessCode,
      order_rrn: orderRrn
    });
  }

  // 8ae85374-683d-4833-998a-231bdb937319


}
