import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CouriersComponent } from './pages/couriers/couriers.component';
import {HttpClientModule} from "@angular/common/http";
import {PipeModule} from "@/_pipes/pipe.module";
import { KitchenComponent } from './pages/kitchen/kitchen.component';
import {ItemLineComponent} from "@/pages/kitchen/item-line/item-line.component";

@NgModule({
  declarations: [
    AppComponent,
    CouriersComponent,
    KitchenComponent,
    ItemLineComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PipeModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
