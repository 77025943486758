import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RestaurantsApiService} from "@/_services/api/restaurants-api.service";
import {catchError, filter, map, switchMap} from "rxjs/operators";
import {BehaviorSubject, combineLatest, Observable, of, timer} from "rxjs";
import {WebsocketService} from "@/_services/api/websocket.service";
import {OrdersService} from "@/_services/api/orders.service";
import * as moment from 'moment';

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.scss']
})
export class KitchenComponent implements OnInit {

  accessToken: string = null;
  identifier: string = null;

  orders = [];
  displayOrders = [];
  enableTable = {};
  colorTable = {};
  actualDisplayOrders: Observable<any>;
  finishedOrders: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  warnTime = -180
  dangerTime = -90

  lastClick = { order: null, time: null }

  colors = {
    currentIndex: 0,
    colors: [
      "#41fdfe",
      "#a0d404",
      "#fe6700",
      "#fe01b1",
      "#8f00f1",
      "#ff000d",
      "#fffd01",
      "#3f00ff",
      "#21fc0d",
      "#ffa600",
      "#f4bfff",
      "#d90166",
      "#bc2731",
      "#fcfd74"
    ],
    orderMap: {}
  }

  constructor(private route: ActivatedRoute, private router: Router, private restaurantsApi: RestaurantsApiService, public ordersService: OrdersService, private websocket: WebsocketService, private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    const code: string = this.route.snapshot.queryParamMap.get('code');
    const identifier: string = this.route.snapshot.queryParamMap.get('identifier');

    if (code) {
      localStorage.setItem('access_token', code);
      localStorage.setItem('identifier', identifier);
    }

    this.actualDisplayOrders = combineLatest(this.ordersService.orders, this.finishedOrders).pipe(
      map(([orders, finished]) => {
        console.log("update orders")

        let oldThis = this;
        function filterItems(items, menuRrn) {
          if (items) {
            let filteredItems = [];

            for (const item of items) {
              if (!oldThis.enableTable.hasOwnProperty(menuRrn) || !oldThis.enableTable[menuRrn].hasOwnProperty(item.menuItemId) || oldThis.enableTable[menuRrn][item.menuItemId]) {
                let filteredModifiers = filterItems(item.modifiers || [], menuRrn);
                item.modifiers = filteredModifiers;
                filteredItems.push(item);
              }
            }

            return filteredItems;
          }
          else
            return []
        }

        let filteredOrders = [];

        for (const order of orders) {
          if (!order.items)
            continue

          let items = order.items;

          let menuRrn = 'rrn:menu:brand:' + order.brandId + ':source:' + order.sourceId;

          if (!oldThis.enableTable.hasOwnProperty(menuRrn) || this.enableTable.hasOwnProperty(menuRrn) && this.enableTable[menuRrn] !== null) {
            items = filterItems(order.items, menuRrn);
          }

          order.items = items;

          if (items.length > 0)
            filteredOrders.push(order);
        }

        console.log(orders.map(or => { return { displayId: or.displayId, status: or.status } }))
        console.log(filteredOrders.filter(order => !finished.includes(order.SK) && order.status === 'accepted'))

        //return filteredOrders
        return filteredOrders.filter(order => !finished.includes(order.SK) && order.status === 'accepted')
      })
    );

    this.ordersService.redisplay.subscribe(order => {
      let finished = this.finishedOrders.value

      let index

      if (index = finished.indexOf(order.SK) >= 0) {
        finished.splice(index, 1)
      }

      this.finishedOrders.next(finished)
    })

    this.accessToken = localStorage.getItem('access_token');
    this.identifier = localStorage.getItem('identifier');

    this.restaurantsApi.getEnabledProductList(this.accessToken, this.identifier).subscribe(table => {
      this.enableTable = table;
      this.setOrders(this.orders)
    });

    this.restaurantsApi.getColorProductList(this.accessToken, this.identifier).subscribe(table => {
      this.colorTable = table;
      this.setOrders(this.orders)
    });

    console.log(this.accessToken);
    console.log(this.identifier);

    if (this.accessToken !== null) {
      this.restaurantsApi.getKitchenDisplayOrders(this.accessToken).pipe(
        catchError(err => of({ orders: [] }))
      ).subscribe(orders => {
        this.setOrders(orders.orders)

        this.next();
      });
    }

    timer(0, 1000).subscribe(_ => {
      this.changeDetection.detectChanges();
    });

    this.ordersService.newOrder.subscribe(_ => {
      this.restaurantsApi.getKitchenDisplayOrders(this.accessToken).subscribe(orders => {
        this.setOrders(orders.orders);
      });
    });
  }

  backgroundColor(order, item) {
    let menuRrn = 'rrn:menu:brand:' + order.brandId + ':source:' + order.sourceId;

    if (this.colorTable.hasOwnProperty(menuRrn) && this.colorTable[menuRrn] !== null && this.colorTable[menuRrn].hasOwnProperty(item.menuItemId)) {
      return this.colorTable[menuRrn][item.menuItemId]
    }

    return "transparent"
  }

  next() {
    let dueTime = 60000;

    if (this.orders.length === 0) { // please spare our bank account
      dueTime = 120000;
    }

    timer(dueTime).pipe(
      switchMap(_ => this.restaurantsApi.getKitchenDisplayOrders(this.accessToken)),
      catchError(err => of({ orders: [] }))
    ).subscribe(orders => {
      this.setOrders(orders.orders)

      this.next();
    });
  }

  setOrders(orders) {
    this.orders = orders;

    let oldThis = this;
    function filterItems(items, menuRrn) {
      let filteredItems = [];

      for (const item of items) {
        if (!oldThis.enableTable.hasOwnProperty(menuRrn) || !oldThis.enableTable[menuRrn].hasOwnProperty(item.menuItemId) || oldThis.enableTable[menuRrn][item.menuItemId]) {
          let filteredModifiers = filterItems(item.modifiers || [], menuRrn);
          item.modifiers = filteredModifiers;
          filteredItems.push(item);
        }
      }

      return filteredItems;
    }

    let filteredOrders = [];

    for (const order of this.orders) {
      if (!order.items)
        continue

      let items = order.items;

      if (items) {
        let menuRrn = 'rrn:menu:brand:' + order.brandId + ':source:' + order.sourceId;

        if (!oldThis.enableTable.hasOwnProperty(menuRrn) || this.enableTable.hasOwnProperty(menuRrn) && this.enableTable[menuRrn] !== null) {
          items = filterItems(order.items, menuRrn);
        }

        order.items = items;

        if (items.length > 0)
          filteredOrders.push(order);
      }
    }

    this.displayOrders = filteredOrders;

    this.ordersService.setOrders(filteredOrders);
  }

  diffTimeForOrder(order: any): number {
    if (order.couriers.length > 0) {
      if (order.couriers[0].estimatedArrival) {
        return this.diffTime(order.couriers[0].estimatedArrival)
      }
    }

    return this.diffTime(order.prepareBy)
  }

  displayDiffTimeForOrder(order: any): string {
    let diffTime = Math.abs(this.diffTimeForOrder(order))
    let hours = 0
    let minutes = 0
    let seconds = 0

    if (diffTime > 3600) {
      hours = Math.floor(diffTime / 3600)
      diffTime -= hours * 3600
    }

    if (diffTime > 60) {
      minutes = Math.floor(diffTime / 60)
      diffTime -= minutes * 60
    }

    seconds = diffTime

    let displayString = ""

    if (hours > 0) {
      displayString = hours + "h"
    }

    if (minutes > 0) {
      displayString += minutes + "m"
    }

    if (minutes === 0 && hours === 0)
      displayString = seconds + "s"

    return displayString
  }

  diffTime(iso8601: string): number {
    let now = moment();
    let diff = moment.duration(now.diff(moment(iso8601)));

    let seconds = Math.round(diff.asSeconds());

    return seconds;
  }

  finishOrder(order) {
    console.log(new Date().getTime() - this.lastClick.time)

    if (this.lastClick.order === order.SK && new Date().getTime() - this.lastClick.time < 500) {
      let currentFinished = this.finishedOrders.value

      currentFinished.push(order.SK)

      this.finishedOrders.next(currentFinished)


      this.restaurantsApi.readyOrder(this.accessToken, order.SK).subscribe(() => {
      })


      console.log(currentFinished)
    }
    else {
      this.lastClick.order = order.SK
      this.lastClick.time = new Date().getTime()

      if (this.colors.orderMap.hasOwnProperty(order.SK)) {
        delete this.colors.orderMap[order.SK]
      }
      else {
        this.colors.orderMap[order.SK] = this.colors.colors[this.colors.currentIndex++]

        if (this.colors.currentIndex === this.colors.colors.length)
          this.colors.currentIndex = 0
      }
    }
  }

  orderStyle(order) {
    if (this.colors.orderMap.hasOwnProperty(order.SK)) {
      return "border-color: " + this.colors.orderMap[order.SK] + ";  outline: 0; box-shadow: 0 0 0 7px " + this.colors.orderMap[order.SK] + "88;"; //rgba(24,144,255,.5);"
    }

    return ""
  }
}
