import { NgModule }      from '@angular/core';
import {I18nPipe} from "./i18n.pipe";

@NgModule({
  imports:        [],
  declarations:   [I18nPipe],
  exports:        [I18nPipe],
})

export class PipeModule {
  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
