// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
export const environment = {
  production: false,
  stripeKey: 'pk_live_KgJytLwOyR5flFp88a7VSNA800X4I3roJu',
  apiUrl: 'https://v5ce0uk933.execute-api.eu-west-3.amazonaws.com/dev',
  url: 'http://localhost:4200',
  websocketUrl: 'wss://g9b6ouvg5d.execute-api.eu-west-3.amazonaws.com/dev',
  cognito: {
    domain: 'auth.heurelimite.fr', //'rushour-dev.auth.eu-west-2.amazoncognito.com',
    clientId: '3enqj67fo4859novh84m042a4a',
    login: '/login',
    logout: '/logout'
  }
};
*/

export const environment = {
  production: true,
  stripeKey: 'pk_live_KgJytLwOyR5flFp88a7VSNA800X4I3roJu',
  //stripeKey: 'pk_test_rnT9nd2cAGRZEVRVxRMNl2Pm00eEZfyJXM',
  apiUrl: 'https://restaurant-api.rushour.io',
  url: 'http://localhost:4200',
  websocketUrl: 'wss://85zi6lqm5h.execute-api.eu-west-3.amazonaws.com/dev',
  cognito: {
    domain: 'auth.rushour.io',
    clientId: '1vmrpu7ppc4vii4p499m103vpq',
    login: '/login',
    logout: '/logout'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
