import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CouriersComponent} from "@/pages/couriers/couriers.component";
import {KitchenComponent} from "@/pages/kitchen/kitchen.component";


const routes: Routes = [
  { path: 'couriers', component: CouriersComponent },
  { path: 'kitchen', component: KitchenComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
