import { Component, OnInit } from '@angular/core';
import {of, timer} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {RestaurantsApiService} from "@/_services/api/restaurants-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WebsocketService} from "@/_services/api/websocket.service";

@Component({
  selector: 'app-couriers',
  templateUrl: './couriers.component.html',
  styleUrls: ['./couriers.component.scss']
})
export class CouriersComponent implements OnInit {

  accessToken: string = null;
  isSmall = false;

  preparing = [];
  ready = [];

  restaurants = null;
  language = null;

  customLogo = null;//"/assets/mmeshawn.svg";
  customLogoSize = null;//"600px";

  constructor(private route: ActivatedRoute, private router: Router, private restaurantsApi: RestaurantsApiService, private websocket: WebsocketService) { }

  ngOnInit(): void {
    const code: string = this.route.snapshot.queryParamMap.get('code');

    if (code) {
      localStorage.setItem('access_token', code);
      //this.router.navigate([ '/couriers' ]);
    }

    const small = this.route.snapshot.queryParamMap.get('small');

    if (small) {
      this.isSmall = true;
    }

    this.accessToken = localStorage.getItem('access_token');

    console.log(this.accessToken);

    if (this.accessToken !== null) {
      this.restaurantsApi.getOrderStatuses(this.accessToken).pipe(
        catchError(err => of({ orders: [] }))
      ).subscribe(orders => {
        let ready = [];
        let preparing = [];

        for (const order of orders.orders) {
          if (order.status === 'ready')
            ready.push(order);
          else
            preparing.push(order);
        }

        this.restaurants = orders.restaurants;
        this.language = orders.language;

        if (orders.customLogo) {
          this.customLogo = orders.customLogo;
        }
        else {
          this.customLogo = null;
        }

        console.log("here")

        if (orders.customLogoSize) {
          this.customLogoSize = orders.customLogoSize;
        }
        else {
          this.customLogoSize = null;
        }

        this.ready = ready;
        this.preparing = preparing;

        this.next();
      });
    }

    this.websocket.onMessage = (message: any) => {
      if (message.type === "order") {
        let newPreparing = [];
        let newReady = this.ready;

        let found = false;

        for (let order of this.preparing) {
          if (order.rrn === message.data.SK) {
            found = true;

            order.status = message.data.status;
          }

          if (order.status !== 'ready')
            newPreparing.push(order);
          else
            newReady.push(order);
        }

        if (!found) {
          this.restaurantsApi.getOrderStatuses(this.accessToken).pipe(
            catchError(err => of({ orders: [] }))
          ).subscribe(orders => {
            let ready = [];
            let preparing = [];

            for (const order of orders.orders) {
              if (order.status === 'ready')
                ready.push(order);
              else
                preparing.push(order);
            }

            this.ready = ready;
            this.preparing = preparing;
          });
        }
        else {
          this.ready = newReady;
          this.preparing = newPreparing;
        }
      }
      else if (message.type === "remove_order") {
        let newPreparing = [];
        let newReady = [];

        for (let order of this.preparing) {
          if (order.rrn !== message.data) {
            newPreparing.push(order);
          }
        }

        for (let order of this.ready) {
          if (order.rrn !== message.data) {
            newPreparing.push(order);
          }
        }

        this.ready = newReady;
        this.preparing = newPreparing;
      }
    };

    if (!this.isSmall) {
      this.preparingScroll();
      this.readyScroll();
    }
  }

  preparingScroll() {
    let elem = document.getElementById('preparing-orders');

    elem.scrollTop = elem.scrollTop + 1;

    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight) {
      let scrolldelay = setTimeout(() => {
        elem.scrollTop = 0;

        setTimeout(() => {
          this.preparingScroll();
        }, 3000);
      }, 3000);
    }
    else {
      let scrolldelay = setTimeout(() => {
        this.preparingScroll();
      }, 10);
    }
  }

  readyScroll() {
    let elem = document.getElementById('ready-orders');

    elem.scrollTop = elem.scrollTop + 1;

    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight) {
      let scrolldelay = setTimeout(() => {
        elem.scrollTop = 0;

        setTimeout(() => {
          this.readyScroll();
        }, 3000);
      }, 3000);
    }
    else {
      let scrolldelay = setTimeout(() => {
        this.readyScroll();
      }, 10);
    }
  }

  next() {
    let dueTime = 30000;

    if (this.preparing.length === 0 && this.ready.length === 0) { // please spare our bank account
      dueTime = 120000;
    }

    timer(dueTime).pipe(
      switchMap(_ => this.restaurantsApi.getOrderStatuses(this.accessToken)),
      catchError(err => of({ orders: [] }))
    ).subscribe(orders => {
      let ready = [];
      let preparing = [];

      for (const order of orders.orders) {
        if (order.status === 'ready')
          ready.push(order);
        else
          preparing.push(order);
      }

      this.ready = ready;
      this.preparing = preparing;

      this.next();
    });
  }

  sourceImage(order) {
    if (order.source.includes('deliveroo'))
      return '/assets/deliveroo.svg'

    if (order.source.includes('justeat'))
      return '/assets/justeat.svg'

    if (order.source === 'epicery' || order.source === 'siouplait')
      return '/assets/' + order.source + ".png";

    return '/assets/' + order.source + ".svg";
  }

  getRestaurant(order) {
    if (this.restaurants && this.restaurants.hasOwnProperty(order.restaurantId))
      return this.restaurants[order.restaurantId]

    return "None"
  }
}
