import { Injectable } from '@angular/core';
import { translations as en } from '../_i18n/en';
import { translations as fr } from '../_i18n/fr';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  translations: {[ key: string ]: any} = {
    'en': en,
    'fr': fr,
  };

  defaultLocale = 'en';
  currentLocale = 'en';

  constructor() {
    if (navigator.languages && navigator.languages.length) {
      this.currentLocale = navigator.languages[0];
    } else {
      this.currentLocale = (navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || 'en';
    }

    let split = this.currentLocale.split('-');
    this.currentLocale = split[0];

    console.log("Language: " + this.currentLocale);
  }

  translate(identifier: string, pluralize?: number): string {
    if (!this.translations.hasOwnProperty(this.currentLocale))
      this.currentLocale = this.defaultLocale;

    if (this.translations[this.currentLocale].hasOwnProperty(identifier)) {
      if (pluralize !== undefined && pluralize !== null) {
        if (pluralize === 0)
          return this.translations[this.currentLocale][identifier].zero;

        if (pluralize === 1)
          return this.translations[this.currentLocale][identifier].singular;

        return this.translations[this.currentLocale][identifier].plural;
      }

      return this.translations[this.currentLocale][identifier];
    }

    return identifier;
  }
}
