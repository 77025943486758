import { Pipe, PipeTransform } from '@angular/core';
import {I18nService} from "@/_services/i18n.service";
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'i18n'})
export class I18nPipe implements PipeTransform {
  constructor(private i18n: I18nService) {
  }

  transform(value: string, pluralize?: number): string {
    return this.i18n.translate(value, pluralize);
  }
}
