<div class="split-container" [class.small]="isSmall">
  <div class="biglogo">
    <div class="split"></div>
    <div class="image-container" *ngIf="customLogo === null">
      <img src="/assets/logo.svg" style="max-width: 1400px; width: 100%; opacity: 30%;" />
    </div>
    <div class="image-container" *ngIf="customLogo !== null" style="padding: 150px 0;">
      <img src="{{ customLogo }}" *ngIf="customLogoSize !== null" style="max-width: 1400px; width: {{ customLogoSize }}; opacity: 30%;" />
      <img src="{{ customLogo }}" *ngIf="customLogoSize === null" style="max-width: 1400px; width: 100%; opacity: 30%;" />
    </div>
  </div>
  <div class="preparing">
    <h2>{{ language ? language.preparing : 'Commandes en préparation' }}</h2>
    <div class="order-list" id="preparing-orders">
      <div *ngFor="let order of preparing" class="order-container">
        <div class="restaurant">{{ getRestaurant(order) }}</div>
        <div class="order">
          <img [src]="sourceImage(order)" />
          <span *ngIf="order.source !== 'deliveroo'" class="customer">{{ order.customerName }}</span>
          <span>#{{ order.displayId }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="ready">
    <h2>{{ language ? language.ready : 'Commandes prêtes' }}</h2>
    <div class="order-list" id="ready-orders">
      <div *ngFor="let order of ready" class="order-container">
        <div class="restaurant">{{ getRestaurant(order) }}</div>
        <div class="order">
          <img [src]="sourceImage(order)" />
          <span *ngIf="order.source !== 'deliveroo'" class="customer">{{ order.customerName }}</span>
          <span>#{{ order.displayId }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="position: absolute; bottom: 10px; right: 30px;" *ngIf="customLogo !== null">
  <img src="/assets/logo.svg" style="width: 150px;" />
</div>
