<div class="container">
  <div class="bar">

  </div>
  <div class="preparing">
    <div class="order-list">
      <div *ngFor="let order of actualDisplayOrders | async" class="order" (click)="finishOrder(order)" style="{{ orderStyle(order) }}">
        <div class="order-title" [class.warning]="diffTimeForOrder(order) > warnTime" [class.danger]="diffTimeForOrder(order) > dangerTime">
          <span class="order-id">
            #{{ order.displayId }}
          </span>
          <span class="spacer"></span>
          <span class="time">{{ diffTimeForOrder(order) < 0 ? displayDiffTimeForOrder(order) : "+" + displayDiffTimeForOrder(order) }}</span>
        </div>
        <div class="order-contents">
          <div *ngFor="let item of order.items">
            <app-item-line [topLevel]="true" [orderItem]="item" [order]="order" [colorTable]="colorTable"></app-item-line>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="position: absolute; bottom: 10px; right: 30px;">
  <div style="text-align: center; color: #fff">KDS #{{ identifier }}</div>
  <img src="/assets/logo.svg" style="width: 150px;" />
</div>
