export let translations = {
  // global menu strings
  "menu.orders": "Orders Manager",
  "menu.dashboard": "Dashboard",
  "menu.locations": "Restaurants",
  "menu.logout": "Déconnexion",

  // dashboard
  "dashboard.no_sources": "Veuillez ajouter une plateforme",
  "dashboard.add_source": "Cliquez ici pour gérer les plateformes",

  // locations
  "locations.title": "Restaurants",
  "locations.location.connections": {
    singular: "intégration",
    plural: "intégrations",
    zero: "intégrations"
  },
  "locations.new-location.button": "Nouveau Restaurant",
  "locations.new-location.modal.title": "Nouveau Restaurant",
  "locations.new-location.modal.parameter.name": "Nom",
  "locations.new-location.modal.parameter.name.required": "Veuillez renseigner le nom du restaurant",
  "locations.new-location.modal.parameter.address": "Adresse",
  "locations.new-location.modal.parameter.address.required": "Veuillez renseigner l'adresse du restaurant",
  "locations.new-location.modal.parameter.address.no-coordinates": "Latitude/longitude introuvable pour cette adresse",

  // referral
  "referral.title": "Parrainage",
  "referral.link": "Mon lien de parrainage :",
  "referral.copy": "Copier",
  "referral.heading": "Recommandez RusHour et recevez 49€!",
  "referral.body": "Copiez le lien ci-dessus, partagez-le à votre réseau, recevez 49 € de crédits RusHour. Pour chaque restaurant, votre prime de parrainage sera automatiquement créditée dès le premier paiement effectué à la fin de la période d’essai. Simple non ?",
  "referral.my_referrals": "Mes Parrainages",

  // location
  "location.connections.tab": "Intégrations",
  "location.connections.brand": "Marque",
  "location.connections.select_brand": "Selectionner Marque",
  "location.connections.new_brand": "Nouvelle Marque",
  "location.connections.source.no_credentials": "Veuillez ajouter vos identifiants",
  "location.connections.source.creating": "Création...",
  "location.connections.source.importing": "Importation de l'Historique...",
  "location.connections.source.created": "OK",

  "location.general.tab": "Général",
  "location.general.restaurant_name": "Nom du Restaurant",
  "location.general.save": "Enregistrer",

  "location.payment.tab": "Facturation",
  "location.payment.default": "Par Défaut",
  "location.payment.make_default": "Marquer comme Defaut",
  "location.payment.cards": "Cartes",
  "location.payment.add_card": "Ajouter Carte",
  "location.payment.sepa": "IBANs",
  "location.payment.add_sepa": "Ajouter IBAN",
  "location.payment.invoices": "Factures",
  "location.payment.download": "Télécharger",

  "location.modules.tab": "Modules Additionnels",
  "location.modules.subscribe": "Souscrire",
  "location.modules.unsubscribe": "Annuler Souscription",

  "location.modals.errors.required": "Obligatoire",
  "location.modals.errors.email": "Email Invalide",
  "location.modals.errors.pattern": "Format Invalide",
  "location.modals.name": "Nom",
  "location.modals.new": "Nouveau",
  "location.modals.edit": "Modifier",
  "location.modals.brand": "Marque",
  "location.modals.add_card": "Nouvelle Carte",
  "location.modals.add_sepa": "Nouveau IBAN",

  "location.modals.order_printer": "Commander une Imprimante",
  "location.modals.order_printer.ok": "Commander",
  "location.modals.order_printer.body": "La mC-Print3 est une imprimante de tickets 80 mm compacte et polyvalente, idéale pour les entreprises utilisant un système de point de vente traditionnel sur PC, et celles qui optent pour la migration vers les tablettes et les points de vente basés sur le Web.",
  "location.modals.order_printer.price": "Prix: 182.99€ HT",

  "location.modals.order_router": "Commander un TP-Link",
  "location.modals.order_router.ok": "Commander",
  "location.modals.order_router.body": "La TP-Link vous permettra de connecter votre imprimante mC-Print3 en wifi.",
  "location.modals.order_router.price": "Prix: 24.90€ HT",

  "location.printers.tab": "Imprimantes",
  "location.printers.title": "Mes Imprimantes",
  "location.printers.order": "J'ai besoin de Commander une Imprimante",
  "location.printers.order_router": "J'ai besoin de Commander un TP-Link",

  // onboarding strings
  "tutorial.order-manager.title": "Gérer vos Commandes",
  "tutorial.order-manager.description": "Cliquez sur ce lien pour gérer toutes vos commandes depuis une seule interface",
  "tutorial.new-location.title": "Ajouter un Restaurant",
  "tutorial.new-location.description": "Cliquez ici pour ajouter votre premier restaurant",
  "tutorial.brand.title": "Gérer Marques",
  "tutorial.brand.description": "Gérez les marques virtuelles de votre restaurant ici",
  "tutorial.source.title": "Ajouter Plateformes",
  "tutorial.source.description": "Ajoutez les plateformes de votre marque ici",
  "tutorial.general.title": "Gérer votre Restaurant",
  "tutorial.general.description": "Vous pouvez gérer les paramètres de votre restaurant ici",
  "tutorial.payment.title": "Ajouter un moyen de paiement",
  "tutorial.payment.description": "Avant de pouvoir souscrire à un module vous devez ajouter un moyen de paiement par défaut",
  "tutorial.modules.title": "Modules Additionnels",
  "tutorial.modules.description": "Ajoutez de nouvelles fonctionnalités à votre restaurant en fonction de vos besoins",
  "tutorial.dashboard.title": "Statistiques Détaillées",
  "tutorial.dashboard.description": "Accédez aux statistiques de vos restaurants ici",

  // signup
  "signup.errors.username.used": "Nom d'utilisateur existe déjà",
  "signup.errors.username.invalid": "Le nom d'utilisateur ne peut pas contenir d'espaces",
  "signup.errors.password.match": "Les mots de passe doivent correspondre",
  "signup.errors.password.invalid": "Mot de passe doit contenir au moins une majuscule, minuscule et chiffre",
  "signup.errors.password.min_length": "8 caractères minimum",
  "signup.errors.phone.invalid": "Invalide",
  "signup.errors.email": "E-mail invalide",
  "signup.errors.required": "Obligatoire",
  "signup.steps.business": "Restaurant",
  "signup.steps.account": "Compte du Restaurant",
  "signup.steps.location": "Adresse",
  "signup.business.title": "Dites nous en plus sur votre restaurant",
  "signup.credentials.title": "Configurez vos identifiants de compte",
  "signup.location.title": "Ajouter votre premier restaurant",
  "signup.next": "Suivant",
  "signup.previous": "Précédent",
  "signup.done": "C'est fait !",
  "signup.number_of_locations": "Nombre de points de vente",
  "signup.contact_name": "Nom du Contact",
  "signup.email": "E-mail",
  "signup.phone": "Numéro de Téléphone Mobile",
  "signup.username": "Nom d'Utilisateur",
  "signup.password": "Mot de Passe",
  "signup.password_confirm": "Confirmer Mot de passe",
  "signup.restaurant_name": "Nom du Restaurant",
  "signup.address": "Adresse",

  // platform errors
  "platform.error.unknown": "Erreur inconnue, veuillez réessayer",
  "takeaway.login.credentials.incorrect": "Identifiants incorrects",
  "justeat.login.credentials.incorrect": "Identifiants incorrects",
  "deliveroo.login.credentials.incorrect": "Identifiants incorrects",
  "deliveroo.login.credentials.invalid": "Identifiants invalides",
  "deliveroo.login.no_restaurants": "Aucun restaurant",
  "deliveroo.login.restaurant_ambiguous": "Choisir restaurant",
  "deliveroo.login.restaurant_not_found": "Restaurant introuvable",
  "epicery.login.credentials.incorrect": "Identifiants incorrects",
  "epicery.login.unknown": "Erreur inconnue, veuillez réessayer",
  "glovo.login.credentials.incorrect": "Identifiants incorrects",
  "glovo.login.unknown": "Erreur inconnue, veuillez réessayer",
  "glovo.login.no_restaurants": "Aucun restaurant",
  "glovo.login.restaurant_ambiguous": "Choisir restaurant",
  "glovo.login.restaurant_not_found": "Restaurant introuvable",
  "ubereats.login.captcha": "Erreur, veuillez réessayer", // 5 anticaptchas n'ont pas suffis... important de dire de réessayer
  "ubereats.login.credentials.email_incorrect": "E-mail incorrect",
  "ubereats.login.credentials.email_unknown": "Erreur inconnue, veuillez réessayer",
  "ubereats.login.invalid_stage": "Étape de connexion inconnue, vérifiez que vous avez le compte tablette",
  "ubereats.login.credentials.password_incorrect": "Mot de passe incorrect",
  "ubereats.login.credentials.password_unknown": "Erreur inconnue, veuillez réessayer",
  "ubereats.login.credentials.pin_incorrect": "Pin incorrect",
  "ubereats.login.credentials.pin_invalid": "Pin invalide",
  "ubereats.login.credentials.pin_unknown": "Erreur inconnue, veuillez réessayer",
};
