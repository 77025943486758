import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  public orders: BehaviorSubject<any[]>;
  private orderDetailCache: { [id: string] : BehaviorSubject<any>; } = {};
  public brandFilter: BehaviorSubject<string[]>;
  public newOrder: Subject<any>;
  public redisplay: Subject<any>;

  private acceptRequests: { [id: string]: Observable<any> } = {};

  constructor() {
    this.orders = new BehaviorSubject<any[]>([]);
    this.brandFilter = new BehaviorSubject<string[]>([]);
    this.newOrder = new Subject<any>();
    this.redisplay = new Subject<any>();
  }

  setOrders(orders) {
    this.orders.next(orders);
  }

  updateOrder(order) {
    /*if (order.status === 'new' && this.restaurants.autoAccept) {
      this.accept(order.SK).subscribe(order => {
        this.updateOrder(order);
      });
    }*/

    let orders = this.orders.value;

    if (orders === null)
      orders = [];

    let found = false;

    for (let i = 0; i < orders.length; i++) {
      if (orders[i].SK === order.SK) {
        order.items = orders[i].items;
        orders[i] = order;
        found = true;
        break;
      }
    }

    if (!found) {
      orders.push(order);

      this.newOrder.next();
    }

    console.log(orders);

    this.orders.next(orders);
  }

  removeOrder(orderRrn) {
    let orders = this.orders.value;

    if (orders === null)
      return;

    delete this.orderDetailCache[orderRrn];

    for (let i = 0; i < orders.length; i++) {
      if (orders[i].SK === orderRrn) {
        orders.splice(i, 1);
        break;
      }
    }

    this.orders.next(orders);
  }

  redisplayOrder(order) {
    this.redisplay.next(order)
  }
}
