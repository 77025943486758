<div class="container" [class.topLevel]="topLevel" [class.hasModifiers]="orderItem.modifiers.length > 0" [class.notTopLevel]="!topLevel" [style.background]="backgroundColor()">
  <div class="line">
    <span class="quantity">{{ displayQuantity(orderItem.quantity) }}</span>
    <span class="name">{{ orderItem.name }}</span>
  </div>
  <div class="modifiers">
    <app-item-line [topLevel]="false" *ngFor="let modifier of orderItem.modifiers" [orderItem]="modifier" [order]="order" [colorTable]="colorTable"></app-item-line>
  </div>
  <div class="instructions" *ngIf="orderItem.instructions">
    {{ orderItem.instructions }}
  </div>
</div>
