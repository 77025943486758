export let translations = {
  // global menu strings
  "menu.orders": "Orders",
  "menu.dashboard": "Dashboard",
  "menu.locations": "Locations",
  "menu.logout": "Log out",

  // dashboard
  "dashboard.no_sources": "You don't have any platforms added yet",
  "dashboard.add_source": "Click here to add one now",

  // locations
  "locations.title": "Locations",
  "locations.location.connections": {
    singular: "connection",
    plural: "connections",
    zero: "connections"
  },
  "locations.new-location.button": "Add Location",
  "locations.new-location.modal.title": "New Location",
  "locations.new-location.modal.parameter.name": "Name",
  "locations.new-location.modal.parameter.name.required": "Name is required.",
  "locations.new-location.modal.parameter.address": "Address",
  "locations.new-location.modal.parameter.address.required": "Address is required.",
  "locations.new-location.modal.parameter.address.no-coordinates": "Latitude/longitude not found for address.",

  // referral
  "referral.title": "Referral",
  "referral.link": "My referral link :",
  "referral.copy": "Copy",
  "referral.heading": "Recommend RusHour and get 49€!",
  "referral.body": "For every restaurant that joins RusHour with your referral link and subscribes past a trial period we'll credit your account with 49€ to be used on RusHour services.",
  "referral.my_referrals": "My Referrals",

  // location
  "location.connections.tab": "Connections",
  "location.connections.brand": "Brand",
  "location.connections.select_brand": "Select Brand",
  "location.connections.new_brand": "New Brand",
  "location.connections.source.no_credentials": "No credentials have been added yet",
  "location.connections.source.creating": "Creating...",
  "location.connections.source.importing": "Importing History...",
  "location.connections.source.created": "OK",

  "location.general.tab": "General",
  "location.general.restaurant_name": "Restaurant Name",
  "location.general.save": "Save",

  "location.payment.tab": "Payment Settings",
  "location.payment.default": "Default Payment Method",
  "location.payment.make_default": "Make Default",
  "location.payment.cards": "Cards",
  "location.payment.add_card": "Add Card",
  "location.payment.sepa": "IBANs",
  "location.payment.add_sepa": "Add IBAN",
  "location.payment.invoices": "Invoices",
  "location.payment.download": "Download",

  "location.modules.tab": "Additional Modules",
  "location.modules.subscribe": "Subscribe",
  "location.modules.unsubscribe": "Cancel Subscription",

  "location.modals.errors.required": "Required",
  "location.modals.errors.email": "Invalid Email",
  "location.modals.errors.pattern": "Invalid Format",
  "location.modals.name": "Name",
  "location.modals.new": "New",
  "location.modals.edit": "Edit",
  "location.modals.brand": "Brand",
  "location.modals.add_card": "New Card",
  "location.modals.add_sepa": "New IBAN",

  "location.modals.order_printer": "Order Printer",
  "location.modals.order_printer.ok": "Order Now",
  "location.modals.order_printer.body": "La mC-Print3 est une imprimante de tickets 80 mm compacte et polyvalente, idéale pour les entreprises utilisant un système de point de vente traditionnel sur PC, et celles qui optent pour la migration vers les tablettes et les points de vente basés sur le Web.",
  "location.modals.order_printer.price": "Price: 182.99€ HT",

  "location.modals.order_router": "Order TP-Link",
  "location.modals.order_router.ok": "Order Now",
  "location.modals.order_router.body": "La TP-Link vous permettra de connecter votre imprimante mC-Print3 en wifi.",
  "location.modals.order_router.price": "Price: 24.90€ HT",

  "location.printers.tab": "Printers",
  "location.printers.title": "My Printers",
  "location.printers.order": "I need to order a Printer",
  "location.printers.order_router": "I need to order a Printer",


  // onboarding strings
  "tutorial.order-manager.title": "Manage Your Orders",
  "tutorial.order-manager.description": "Click on this link to manage all your orders from a single interface",
  "tutorial.new-location.title": "Add a new location",
  "tutorial.new-location.description": "Click here to add your first location",
  "tutorial.brand.title": "Manage Brands",
  "tutorial.brand.description": "Manage the virtual brands in your location here",
  "tutorial.source.title": "Add Platforms",
  "tutorial.source.description": "Add your brands' OFO platforms here",
  "tutorial.general.title": "Manage Restaurant",
  "tutorial.general.description": "You can manage your restaurant's settings here",
  "tutorial.payment.title": "Add a payment method",
  "tutorial.payment.description": "Before you can subscribe to additional modules you need to add a payment method",
  "tutorial.modules.title": "Additional Modules",
  "tutorial.modules.description": "Add functionality to your restaurant",
  "tutorial.dashboard.title": "View Detailed Reports",
  "tutorial.dashboard.description": "View detailed reports on your locations here",

  // signup
  "signup.errors.username.used": "Already in use",
  "signup.errors.username.invalid": "Username cannot contain spaces",
  "signup.errors.password.match": "Passwords must match",
  "signup.errors.password.invalid": "Password must contain at least one lowercase, uppercase and numeric character and be 8 characters long",
  "signup.errors.password.min_length": "Password must be 8 characters long",
  "signup.errors.phone.invalid": "Invalid Phone",
  "signup.errors.email": "Invalid E-mail",
  "signup.errors.required": "Required",
  "signup.steps.business": "Business",
  "signup.steps.account": "Account",
  "signup.steps.location": "Location",
  "signup.business.title": "Tell us all about your business",
  "signup.credentials.title": "Set up your account credentials",
  "signup.location.title": "Add your first location",
  "signup.next": "Next",
  "signup.previous": "Previous",
  "signup.done": "Done",
  "signup.number_of_locations": "Number of locations",
  "signup.contact_name": "Contact Name",
  "signup.email": "E-mail",
  "signup.phone": "Phone",
  "signup.username": "Username",
  "signup.password": "Password",
  "signup.password_confirm": "Confirm password",
  "signup.restaurant_name": "Restaurant Name",
  "signup.address": "Address",

  // platform errors
  "platform.error.unknown": "Unknown error, please try again",
  "takeaway.login.credentials.incorrect": "Incorrect credentials",
  "justeat.login.credentials.incorrect": "Incorrect credentials",
  "deliveroo.login.credentials.incorrect": "Incorrect credentials",
  "deliveroo.login.credentials.invalid": "Invalid credentials",
  "deliveroo.login.no_restaurants": "No restaurants",
  "deliveroo.login.restaurant_ambiguous": "Select restaurant",
  "deliveroo.login.restaurant_not_found": "Restaurant not found",
  "epicery.login.credentials.incorrect": "Incorrect credentials",
  "epicery.login.unknown": "Unknown error, please try again",
  "glovo.login.credentials.incorrect": "Incorrect credentials",
  "glovo.login.unknown": "Unknown error, please try again",
  "glovo.login.no_restaurants": "No restaurants",
  "glovo.login.restaurant_ambiguous": "Select restaurant",
  "glovo.login.restaurant_not_found": "Restaurant not found",
  "ubereats.login.captcha": "Error, please try again", // 5 anticaptchas n'ont pas suffis... important de dire de réessayer
  "ubereats.login.credentials.email_incorrect": "Incorrect e-mail",
  "ubereats.login.credentials.email_unknown": "Unknown error, please try again",
  "ubereats.login.invalid_stage": "Unknown login stage, check you have the dashboard account",
  "ubereats.login.credentials.password_incorrect": "Incorrect password",
  "ubereats.login.credentials.password_unknown": "Unknown error, please try again",
  "ubereats.login.credentials.pin_incorrect": "Incorrect pin",
  "ubereats.login.credentials.pin_invalid": "Invalid pin",
  "ubereats.login.credentials.pin_unknown": "Unknown error, please try again",
};
