import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-item-line',
  templateUrl: './item-line.component.html',
  styleUrls: ['./item-line.component.scss']
})
export class ItemLineComponent implements OnInit {

  @Input() orderItem: any;
  @Input() topLevel: boolean = true;
  @Input() colorTable: any = {};
  @Input() order: any = null;

  constructor() { }

  ngOnInit(): void {
  }

  displayQuantity(quantity: number) {
    if (quantity === 1) {
      return this.topLevel ? '1x' : '-';
    }

    return quantity + "x";
  }

  backgroundColor() {
    if (this.order && this.orderItem) {
      let menuRrn = 'rrn:menu:brand:' + this.order.brandId + ':source:' + this.order.sourceId;

      if (this.colorTable.hasOwnProperty(menuRrn) && this.colorTable[menuRrn] !== null && this.colorTable[menuRrn].hasOwnProperty(this.orderItem.menuItemId)) {
        return this.colorTable[menuRrn][this.orderItem.menuItemId]
      }
    }

    return "transparent"
  }
}
